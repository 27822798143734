@media only screen and (max-width: 550px) {
    h3 {
        font-size: 24px;
        letter-spacing: .02666667px;
    }
}

h3 {
    font-family: freight-sans-pro;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.2em;
    margin: 0;
}
.content {
    background-color: #fff;
    color: #1b2c5a;
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    padding: 1em;
}
@media only screen and (min-width: 500px) {
    form {
        width: 66.6667%;
        margin: auto;
    }
}

form .field {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 1em;
    margin-top: 1em;
}

.field label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

form .field.error label {
    color: red;
}

form .field.error .errorMessage {
    color: red;
    display: block;
}

form .field .errorMessage {
    display: none;
    font-size: medium;
}

input {
    font-size: large;
    padding: 0.4em;
    margin-bottom: 0.25em;
    margin-top: 0.25em;

    border: 1px solid #ccc;
    background: #fafafa;
    color: #000;
    font-family: sans-serif;
    border-radius: 2px;
}

input:focus {
    background: #fff;
    transition: background 100ms ease-in;
}

.field.error input {
    border-color: red;
}

.buttons {
    text-align: right;
}

button {
    font-size: large;
    flex: 0;
    padding: 0.25em;
}
